export const transformItemsMonth = (items) => {
  const monthOrder = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  return items.sort((a, b) => {
    const aIndex = monthOrder.indexOf(a.value);
    const bIndex = monthOrder.indexOf(b.value);
    return aIndex - bIndex;
  });
};

export const transformItemsInt = (items) => {
  return items.sort((a, b) => parseInt(a.value, 10) - parseInt(b.value, 10));
};

export const transformItemsCourt = (items) => {
  return items.map((item) => {
    if (item.label === 'SC') {
      return { ...item, label: 'Supreme Court' };
    } else if (item.label === 'CA') {
      return { ...item, label: 'Court of Appeal' };
    } else if (item.label === 'CHC') {
      return { ...item, label: 'Commercial High Court' };
    } else if (item.label.includes('HCCA')) {
      const [province, court] = item.label.split('-');
      return { ...item, label: `High Court Civil Appeal - ${province}` };
    } else if (item.label.includes('PHC')) {
      const [province, court] = item.label.split('-');
      return { ...item, label: `Provincial High Court - ${province}` };
    }

    return item;
  });
};
