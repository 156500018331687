import React from 'react';
import logoImage from '../../assets/images/fav.webp';

const Logo = ({ small }) => {
  return (
    <div
      className={`flex items-center ${
        small
          ? 'gap-2 w-[190px]'
          : 'gap-10 p-10 sm:p-20 w-full max-w-screen-lg'
      }`}
    >
      <div
        className={`${
          small
            ? 'w-[30px] h-[30px]'
            : 'w-[40px] h-[40px] sm:w-[60px] sm:h-[60px]'
        } bg-cover flex-none`}
        style={{ backgroundImage: `url(${logoImage})` }}
      ></div>

      <div
        className={`${
          small
            ? 'w-auto text-[24px] leading-[28px] py-5'
            : 'w-full text-[30px] sm:text-[48px] leading-[52px] pb-10'
        } font-semibold dark:text-pl_txt_dark_1 bg-gradient-to-br from-[#dd0c7e] via-[#ca1582] to-[#a6208e] text-transparent bg-clip-text`}
      >
        paralegal.lk
      </div>
    </div>
  );
};

export default Logo;
